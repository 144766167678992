import type { LinkProps } from 'next/link';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { PropsWithChildren } from 'react';
import React, { useEffect, useState } from 'react';

type ActiveLinkProps = LinkProps & {
  className?: string;
  activeClassName: string;
};

const ActiveLink = ({ children, activeClassName, className, ...props }: PropsWithChildren<ActiveLinkProps>) => {
  const { asPath, isReady } = useRouter();
  const [computedClassName, setComputedClassName] = useState(className);

  useEffect(() => {
    if (isReady) {
      const linkPathname = new URL((props.as || props.href) as string, window.location.href).pathname;

      const activePathname = new URL(asPath, window.location.href).pathname;

      const isActive = activePathname === linkPathname || activePathname.startsWith(`${linkPathname}/`);

      const newClassName = isActive ? `${`${className} `}  ${activeClassName}`.trim() : `${className} text-white`;

      if (newClassName !== computedClassName) {
        setComputedClassName(newClassName);
      }
    }
  }, [asPath, isReady, props.as, props.href, activeClassName, className, computedClassName]);

  return (
    <Link className={`${computedClassName}`} {...props}>
      {children}
    </Link>
  );
};

export default ActiveLink;
