import classNames from 'classnames';
import Script from 'next/script';
import { type ReactNode, useEffect } from 'react';

type IMainProps = {
  meta?: ReactNode;
  children: ReactNode;
  className?: string;
};

const urlSdk = process.env.NEXT_PUBLIC_URL_TRACKEDX ?? '';

const tokenSdk = process.env.NEXT_PUBLIC_TOKEN_SDK ?? '';

const Main = (props: IMainProps) => {
  useEffect(() => {
    // @ts-ignore
    if (typeof window !== 'undefined' && window.Affiliate) {
      // @ts-ignore
      Affiliate.init(urlSdk, tokenSdk);
    }
  }, []);
  return (
    <div className={classNames('w-full text-gray-700 antialiased', props.className)}>
      <Script src='/affiliates-sdk.min.js' strategy='beforeInteractive' />
      {props.meta}
      <main className='content'>{props.children}</main>
    </div>
  );
};

export { Main };
