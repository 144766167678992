import classNames from 'classnames';
import React from 'react';
import { VscLoading as IconLoading } from 'react-icons/vsc';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'white' | 'blue' | 'gray' | 'gray-light';
  size?: 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = (props) => {
  const { children, variant, className, fullWidth, size = 'md', type = 'button', loading = false, ...otherProps } = props;

  const buttonClassNames = classNames(
    'w-full uppercase',
    {
      'tablet:w-fit': !fullWidth,
    },
    'text-center transition-all duration-200 hover:shadow-xl',
    'font-bold',
    {
      'text-sm rounded-lg py-2.5 px-2.5 ': size === 'sm',
      'text-sm rounded-xl py-2.5 px-2.5 mobile:px-10 ': size === 'md',
      'text-lg': size === 'lg',
    },
    loading ? 'cursor-not-allowed' : 'cursor-pointer',
    loading ? 'bg-gray-300 text-gray-500' : 'hover:bg-opacity-90',
    loading && 'flex items-center  justify-center',
    variant === 'primary' && 'bg-green-300 text-black',
    variant === 'secondary' && 'bg-blue-300 text-white',
    variant === 'gray' && 'bg-gray-300 text-white',
    variant === 'gray-light' && 'bg-[#879BAB] text-[#D6E4EF]',
    variant === 'white' && 'bg-white text-black',
    variant === 'blue' && 'bg-[#065BFF] text-[#CCDDFF]',
    className
  );

  return (
    <button {...otherProps} type={type} className={buttonClassNames} disabled={loading}>
      {loading && <IconLoading className='h-20px w-20px mr-2 animate-spin' />}
      {children}
    </button>
  );
};

export default Button;
