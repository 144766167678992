const LanguagesEnum = {
  ENGLISH: { slug: 'en', name: 'english', title: 'en' },
  RUSSIAN: { slug: 'ru', name: 'russian', title: 'ru' },
  PORTUGUESE: { slug: 'pt', name: 'portuguese', title: 'pt' },
  FRENCH: { slug: 'fr', name: 'french', title: 'fr' },
  SPANISH: { slug: 'es', name: 'spanish', title: 'es' },
  TURKISH: { slug: 'tr', name: 'turkish', title: 'tr' },
  ARABIAN: { slug: 'sa', name: 'arabian', title: 'sa' },
  CHINESE: { slug: 'cn', name: 'chinese', title: 'cn' },
  JAPANESE: { slug: 'jp', name: 'japanese', title: 'jp' },
};

export const findBySlug = (slug: string) => {
  const lang = Object.values(LanguagesEnum).find((language) => language.slug === slug);

  if (!lang) {
    return LanguagesEnum.ENGLISH;
  }

  return lang;
};

export default LanguagesEnum;
