import { useTranslation } from 'next-i18next';

export function CountryNamesPhone() {
  const { t } = useTranslation('components/country-name');

  const country = {
    Afghanistan: t('country_code.af'),
    Albania: t('country_code.al'),
    Germany: t('country_code.de'),
    Andorra: t('country_code.ad'),
    Angola: t('country_code.ao'),
    Anguilla: t('country_code.ai'),
    Antarctica: t('country_code.aq'),
    'Antigua and Barbuda': t('country_code.ag'),
    'Saudi Arabia': t('country_code.sa'),
    Algeria: t('country_code.dz'),
    Argentina: t('country_code.ar'),
    Armenia: t('country_code.am'),
    Aruba: t('country_code.aw'),
    Australia: t('country_code.au'),
    Austria: t('country_code.at'),
    Azerbaijan: t('country_code.az'),
    Bahamas: t('country_code.bs'),
    Bangladesh: t('country_code.bd'),
    Barbados: t('country_code.bb'),
    Bahrain: t('country_code.bh'),
    Belgium: t('country_code.be'),
    Belize: t('country_code.bz'),
    Benin: t('country_code.bj'),
    Bermuda: t('country_code.bm'),
    Belarus: t('country_code.by'),
    Bolivia: t('country_code.bo'),
    'Bosnia and Herzegovina': t('country_code.ba'),
    Botswana: t('country_code.bw'),
    Brazil: t('country_code.br'),
    Brunei: t('country_code.bn'),
    Bulgaria: t('country_code.bg'),
    'Burkina Faso': t('country_code.bf'),
    Burundi: t('country_code.bi'),
    Bhutan: t('country_code.bt'),
    'Cape Verde': t('country_code.cv'),
    Cambodia: t('country_code.kh'),
    Cameroon: t('country_code.cm'),
    Canada: t('country_code.ca'),
    'Canary Islands': t('country_code.ic'),
    'Caribbean Netherlands': t('country_code.bq'),
    Qatar: t('country_code.qa'),
    'Ceuta and Melilla': t('country_code.ea'),
    Chad: t('country_code.td'),
    Czechia: t('country_code.cz'),
    Chile: t('country_code.cl'),
    China: t('country_code.cn'),
    Cyprus: t('country_code.cy'),
    'Vatican City': t('country_code.va'),
    Colombia: t('country_code.co'),
    Comoros: t('country_code.km'),
    Congo: t('country_code.cg'),
    'North Korea': t('country_code.kp'),
    'South Korea': t('country_code.kr'),
    'Costa Rica': t('country_code.cr'),
    'Ivory Coast': t('country_code.ci'),
    Croatia: t('country_code.hr'),
    Cuba: t('country_code.cu'),
    Curaçao: t('country_code.cw'),
    'Diego Garcia': t('country_code.dg'),
    Denmark: t('country_code.dk'),
    Dominica: t('country_code.dm'),
    Ecuador: t('country_code.ec'),
    Egypt: t('country_code.eg'),
    'El Salvador': t('country_code.sv'),
    'United Arab Emirates': t('country_code.ae'),
    Eritrea: t('country_code.er'),
    Slovakia: t('country_code.sk'),
    Slovenia: t('country_code.si'),
    Spain: t('country_code.es'),
    'United States': t('country_code.us'),
    Estonia: t('country_code.ee'),
    Eswatini: t('country_code.sz'),
    Ethiopia: t('country_code.et'),
    Philippines: t('country_code.ph'),
    Finland: t('country_code.fi'),
    Fiji: t('country_code.fj'),
    France: t('country_code.fr'),
    Gabon: t('country_code.ga'),
    Gambia: t('country_code.gm'),
    Georgia: t('country_code.ge'),
    Ghana: t('country_code.gh'),
    Gibraltar: t('country_code.gi'),
    Grenada: t('country_code.gd'),
    Greece: t('country_code.gr'),
    Greenland: t('country_code.gl'),
    Guadeloupe: t('country_code.gp'),
    Guam: t('country_code.gu'),
    Guatemala: t('country_code.gt'),
    'French Guiana': t('country_code.gf'),
    Guernsey: t('country_code.gg'),
    Guinea: t('country_code.gn'),
    'Equatorial Guinea': t('country_code.gq'),
    'Guinea-Bissau': t('country_code.gw'),
    Guyana: t('country_code.gy'),
    Haiti: t('country_code.ht'),
    Honduras: t('country_code.hn'),
    Hungary: t('country_code.hu'),
    India: t('country_code.in'),
    Indonesia: t('country_code.id'),
    Iraq: t('country_code.iq'),
    Iran: t('country_code.ir'),
    Ireland: t('country_code.ie'),
    'Ascension Island': t('country_code.ac'),
    'Isle of Man': t('country_code.im'),
    'Christmas Island': t('country_code.cx'),
    'Norfolk Island': t('country_code.nf'),
    Iceland: t('country_code.is'),
    'Åland Islands': t('country_code.ax'),
    'Cayman Islands': t('country_code.ky'),
    'Cocos Islands': t('country_code.cc'),
    'Cook Islands': t('country_code.ck'),
    'Faroe Islands': t('country_code.fo'),
    'South Georgia and the South Sandwich Islands': t('country_code.gs'),
    'Falkland Islands': t('country_code.fk'),
    'Northern Mariana Islands': t('country_code.mp'),
    'Marshall Islands': t('country_code.mh'),
    'United States Minor Outlying Islands': t('country_code.um'),
    'Pitcairn Islands': t('country_code.pn'),
    'Solomon Islands': t('country_code.sb'),
    'Turks and Caicos Islands': t('country_code.tc'),
    'British Virgin Islands': t('country_code.vg'),
    'United States Virgin Islands': t('country_code.vi'),
    Israel: t('country_code.il'),
    Italy: t('country_code.it'),
    Jamaica: t('country_code.jm'),
    Japan: t('country_code.jp'),
    Jersey: t('country_code.je'),
    Jordan: t('country_code.jo'),
    Kazakhstan: t('country_code.kz'),
    Kenya: t('country_code.ke'),
    Kyrgyzstan: t('country_code.kg'),
    Kiribati: t('country_code.ki'),
    Kosovo: t('country_code.xk'),
    Kuwait: t('country_code.kw'),
    Laos: t('country_code.la'),
    Lesotho: t('country_code.ls'),
    Latvia: t('country_code.lv'),
    Lebanon: t('country_code.lb'),
    Liberia: t('country_code.lr'),
    Libya: t('country_code.ly'),
    Liechtenstein: t('country_code.li'),
    Lithuania: t('country_code.lt'),
    Luxembourg: t('country_code.lu'),
    'North Macedonia': t('country_code.mk'),
    Madagascar: t('country_code.mg'),
    Malaysia: t('country_code.my'),
    Malawi: t('country_code.mw'),
    Maldives: t('country_code.mv'),
    Mali: t('country_code.ml'),
    Malta: t('country_code.mt'),
    Morocco: t('country_code.ma'),
    Martinique: t('country_code.mq'),
    Mauritius: t('country_code.mu'),
    Mauritania: t('country_code.mr'),
    Mayotte: t('country_code.yt'),
    Mexico: t('country_code.mx'),
    Micronesia: t('country_code.fm'),
    Moldova: t('country_code.md'),
    Monaco: t('country_code.mc'),
    Mongolia: t('country_code.mn'),
    Montenegro: t('country_code.me'),
    Montserrat: t('country_code.ms'),
    Mozambique: t('country_code.mz'),
    Myanmar: t('country_code.mm'),
    Namibia: t('country_code.na'),
    Nauru: t('country_code.nr'),
    Nepal: t('country_code.np'),
    Nicaragua: t('country_code.ni'),
    Niger: t('country_code.ne'),
    Nigeria: t('country_code.ng'),
    Niue: t('country_code.nu'),
    Norway: t('country_code.no'),
    'New Caledonia': t('country_code.nc'),
    'New Zealand': t('country_code.nz'),
    Oman: t('country_code.om'),
    Netherlands: t('country_code.nl'),
    Pakistan: t('country_code.pk'),
    Palau: t('country_code.pw'),
    Panama: t('country_code.pa'),
    'Papua New Guinea': t('country_code.pg'),
    Paraguay: t('country_code.py'),
    Peru: t('country_code.pe'),
    'French Polynesia': t('country_code.pf'),
    Poland: t('country_code.pl'),
    Portugal: t('country_code.pt'),
    'Puerto Rico': t('country_code.pr'),
    'Hong Kong SAR': t('country_code.hk'),
    'Macau SAR': t('country_code.mo'),
    'United Kingdom': t('country_code.gb'),
    'Central African Republic': t('country_code.cf'),
    'Democratic Republic of the Congo': t('country_code.cd'),
    'Dominican Republic': t('country_code.do'),
    Réunion: t('country_code.re'),
    Rwanda: t('country_code.rw'),
    Romania: t('country_code.ro'),
    Russia: t('country_code.ru'),
    'Western Sahara': t('country_code.eh'),
    Samoa: t('country_code.ws'),
    'American Samoa': t('country_code.as'),
    'Saint Barthélemy': t('country_code.bl'),
    'Saint Kitts and Nevis': t('country_code.kn'),
    'San Marino': t('country_code.sm'),
    'Saint Martin': t('country_code.mf'),
    'Saint Pierre and Miquelon': t('country_code.pm'),
    'Saint Vincent and the Grenadines': t('country_code.vc'),
    'Saint Helena': t('country_code.sh'),
    'Saint Lucia': t('country_code.lc'),
    'São Tomé and Príncipe': t('country_code.st'),
    Senegal: t('country_code.sn'),
    Serbia: t('country_code.rs'),
    Seychelles: t('country_code.sc'),
    'Sierra Leone': t('country_code.sl'),
    Singapore: t('country_code.sg'),
    'Sint Maarten': t('country_code.sx'),
    Syria: t('country_code.sy'),
    Somalia: t('country_code.so'),
    'Sri Lanka': t('country_code.lk'),
    'South Africa': t('country_code.za'),
    Sudan: t('country_code.sd'),
    'South Sudan': t('country_code.ss'),
    Sweden: t('country_code.se'),
    Switzerland: t('country_code.ch'),
    Suriname: t('country_code.sr'),
    'Svalbard and Jan Mayen': t('country_code.sj'),
    Thailand: t('country_code.th'),
    Taiwan: t('country_code.tw'),
    Tanzania: t('country_code.tz'),
    Tajikistan: t('country_code.tj'),
    'British Indian Ocean Territory': t('country_code.io'),
    'French Southern and Antarctic Lands': t('country_code.tf'),
    'Palestinian Territories': t('country_code.ps'),
    'Timor-Leste': t('country_code.tl'),
    Togo: t('country_code.tg'),
    Tokelau: t('country_code.tk'),
    Tonga: t('country_code.to'),
    'Trinidad and Tobago': t('country_code.tt'),
    'Tristan da Cunha': t('country_code.ta'),
    Tunisia: t('country_code.tn'),
    Turkmenistan: t('country_code.tm'),
    Turkey: t('country_code.tr'),
    Tuvalu: t('country_code.tv'),
    Ukraine: t('country_code.ua'),
    Uganda: t('country_code.ug'),
    Uruguay: t('country_code.uy'),
    Uzbekistan: t('country_code.uz'),
    Vanuatu: t('country_code.vu'),
    Venezuela: t('country_code.ve'),
    Vietnam: t('country_code.vn'),
    'Wallis and Futuna': t('country_code.wf'),
    Yemen: t('country_code.ye'),
    Djibouti: t('country_code.dj'),
    Zambia: t('country_code.zm'),
    Zimbabwe: t('country_code.zw'),
  };
  return country;
}
