import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { MouseEvent } from 'react';
import { useState } from 'react';
import { IoChevronForward } from 'react-icons/io5';

import CountryFlag from '@/common/country-flag';
import LanguagesEnum, { findBySlug } from '@/enums/languages-enum';

export default function LanguageSwitcher() {
  const { i18n } = useTranslation('common');
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);

  const selectedLanguage = {
    slug: i18n.language,
    title: findBySlug(i18n.language).title,
  };

  const handleChangeLanguage = (e: MouseEvent<HTMLButtonElement>, lang: string) => {
    e.preventDefault();
    router.push(
      {
        pathname: router.pathname,
        query: router.query,
      },
      // @ts-ignore
      null,
      { locale: lang }
    );
    setMenuOpen(false); // Fechar o menu após a seleção do idioma
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className='mr-2 w-20 mobile:mr-0'>
      <div className='cursor-pointer' onClick={toggleMenu}>
        <div className='flex-col items-center justify-start gap-2.5 rounded-[77px] border border-gray-800 px-2.5 py-[9px]'>
          <div className='relative flex h-[19px] w-auto items-center gap-2 mobile:w-auto'>
            <CountryFlag country={selectedLanguage?.slug ?? 'en'} alt={selectedLanguage?.title ?? 'English'} className='size-5 shrink-0' />
            <div className='text-xs font-bold uppercase text-gray-200'>{selectedLanguage?.title ?? 'English'}</div>
            <div className={`text-white transition-all ${menuOpen && 'rotate-90'}`}>
              <IoChevronForward size={12} />
            </div>
          </div>
        </div>
      </div>

      {menuOpen && (
        <div className='absolute inset-x-0 pt-2 xl:inset-x-auto xl:w-52'>
          <div className='flex w-full gap-2 overflow-auto rounded-md bg-[#0E0F12] p-2 xl:w-auto'>
            <div className='grid w-full grid-cols-3 gap-1 xl:w-auto'>
              {Object.values(LanguagesEnum).map((lang) => (
                <button onClick={(e) => handleChangeLanguage(e, lang.slug)} className='flex w-full items-center justify-center rounded p-1 hover:bg-white/20' key={`country-${lang.slug}`}>
                  <div className='flex flex-row items-center rounded-md'>
                    <CountryFlag country={lang.slug} alt={lang.title} className='size-6' />
                    <span className='ml-3 border-b-2 border-transparent text-sm font-bold uppercase leading-[30.80px] text-slate-300'>{lang.title}</span>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
