import { NextSeo } from 'next-seo';

import { AppConfig } from '@/utils/app-config';

type NextSeoComponentProps = {
  title: string;
  description: string;
  canonical?: string;
};

export default function NextSeoComponent({ title, description, canonical }: NextSeoComponentProps) {
  return (
    <NextSeo
      title={title}
      description={description}
      canonical={canonical}
      openGraph={{
        title,
        description,
        url: canonical,
        locale: AppConfig.locale,
        site_name: AppConfig.site_name,
      }}
    />
  );
}
