import Image from 'next/image';
import React from 'react';

interface CountryFlagProps {
  country: string;
  alt: string;
  className?: string;
}

const CountryFlag = ({ country, alt, className }: CountryFlagProps) => {
  return (
    <div>
      <Image width={20} height={20} src={`/assets/images/countries/${country}.svg`} alt={alt} className={className} />
    </div>
  );
};

export default CountryFlag;
